//Redux boilerplate
import { applyMiddleware, createStore, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import get from 'lodash/get';
import isNil from 'lodash/isNil';

import Airbrake from 'common/airbrake';
import { getCurrentDomain } from 'common/currentDomain';
import type InteractiveUser from 'common/types/users/interactiveUser';

import reducer, { initialAppTokensState, initialTeamState } from './reducer';

import sagas from './sagas';

export const getGlobalUsersInfo = (): { user: InteractiveUser; currentUser: InteractiveUser } => {
  const user = get(window, 'socrata.profile.user');
  const currentUser = get(window, 'socrata.currentUser');
  if (isNil(user) || isNil(currentUser)) {
    const errorMessage = `Unable to get user object from ${isNil(user) ? 'socrata.profile.user' : ''}${
      isNil(currentUser) ? ' socrata.currentUser' : ''
    }`;
    Airbrake.notify({
      error: errorMessage,
      context: { component: 'account/view/createStore$getGlobalUserInfo' }
    });
    console.error(errorMessage);
  }
  user.domain = getCurrentDomain();
  return { user, currentUser };
};

const createAccountViewStore = (initialState: { user: InteractiveUser; currentUser: InteractiveUser }) => {
  const devToolsConfig = {
    name: 'User Account View'
  };

  const composeEnhancers =
    (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(devToolsConfig)) ||
    compose;

  const preloadedState = {
    ...initialState,
    teams: {
      ...initialTeamState
    },
    appTokens: {
      ...initialAppTokensState
    }
  };

  const sagaMiddleware = createSagaMiddleware();
  // we don't allow state to be undefined
  // @ts-expect-error TS(2345) FIXME: Argument of type '(state: ProfileState, action: { ... Remove this comment to see the full error message
  const store = createStore(reducer, preloadedState, composeEnhancers(applyMiddleware(sagaMiddleware)));
  sagaMiddleware.run(sagas);

  return store;
};

export default createAccountViewStore;

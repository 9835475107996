import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import * as TeamsCatalogApi from 'common/teams-api';
import CoreRolesApi from 'common/core/roles';
import EmailVerificationApi from 'common/core/email_verification';

import * as AppTokenUtils from 'account/edit/components/DeveloperSettingsPane/AppTokensTable/AppTokenUtils.js';
import * as Actions from './actions';
import * as Selectors from './selectors';
import { AppTokenType } from 'account/components/AppTokensTable/types';
import { ProfileState } from 'account/view/types';

/** FOR TEAMS **/
const teamSelectOptions = (state: ProfileState) => ({
  offset: Selectors.getTeamsOffset(state),
  query: Selectors.getTeamsSearchQuery(state),
  limit: Selectors.getTeamsResultsLimit(state),
  orderBy: Selectors.getTeamsOrderBy(state),
  sortDirection: Selectors.getTeamsSortDirection(state)
});

export function* gotoPage() {
  yield put(Actions.teamsSearch());
}

export function* fetchTeams() {
  // TODO: Add appropriate return type to generator so yield doesn't return any
  // @ts-expect-error TS(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
  const options = yield select(teamSelectOptions);
  // TODO: Add appropriate return type to generator so yield doesn't return any
  // @ts-expect-error TS(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
  const domain = yield select(Selectors.getDomain);
  // TODO: Add appropriate return type to generator so yield doesn't return any
  // @ts-expect-error TS(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
  const uid = yield select(Selectors.getId);
  // TODO: Add appropriate return type to generator so yield doesn't return any
  // @ts-expect-error TS(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
  return yield call(TeamsCatalogApi.getTeamsContainingUser, domain, uid, options);
}

export function* loadTeams() {
  try {
    const { teams, resultCount } = yield call(fetchTeams);
    yield put(Actions.loadTeamsSuccess(teams, resultCount));
  } catch (error) {
    yield put(Actions.loadTeamsFailure(error));
  }
}

export function* teamsSearch() {
  try {
    const { teams, resultCount } = yield call(fetchTeams);
    yield put(Actions.teamsSearchSuccess(teams, resultCount));
  } catch (error) {
    console.warn('Unable to search for teams, using empty list.', error);
    yield put(Actions.teamsSearchFailure(error));
  }
}

export function* sortTeamColumn() {
  yield put(Actions.teamsSearch());
}

export const teamSagas = [
  takeEvery(Actions.GOTO_TEAM_PAGE, gotoPage),
  takeEvery(Actions.LOAD_TEAMS, loadTeams),
  takeEvery(Actions.SORT_TEAM_COLUMN, sortTeamColumn),
  takeLatest(Actions.TEAMS_SEARCH, teamsSearch)
];

/** FOR APP TOKENS **/
export function* fetchAppTokens() {
  // TODO: Add appropriate return type to generator so yield doesn't return any
  // @ts-expect-error TS(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
  const uid = yield select(Selectors.getId);
  // TODO: Add appropriate return type to generator so yield doesn't return any
  // @ts-expect-error TS(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
  return yield call(AppTokenUtils.fetchAppTokens, uid);
}

export function* loadAppTokens() {
  try {
    // TODO: Add appropriate return type to generator so yield doesn't return any
    // @ts-expect-error TS(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
    const appTokens = yield call(fetchAppTokens);

    // get rid of useless metadata in tokens
    appTokens.forEach((token: AppTokenType) => {
      delete token.owner;
      delete token.public;
    });

    yield put(Actions.loadAppTokensSuccess(appTokens));
  } catch (error) {
    yield put(Actions.loadAppTokensFailure(error));
  }
}

export const appTokenSagas = [takeEvery(Actions.LOAD_APP_TOKENS, loadAppTokens)];

/** FOR ROLES */

/**
 * Uses the generated roles API to fetch a role from core.
 *
 * Dispatched when looking at another user's profile and you have the manage_users right.
 */
export function* fetchRole({ payload: { roleId } }: Actions.FetchRole) {
  try {
    // TODO: Add appropriate return type to generator so yield doesn't return any
    // @ts-expect-error TS(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
    const role = yield call([CoreRolesApi, CoreRolesApi.getRole], { roleId });

    yield put(Actions.fetchRoleSuccess(role));
  } catch (error) {
    console.error(`Error fecthing role ${roleId}; `, error);
    yield put(Actions.fetchRoleFailure(error));
  }
}

/**
 * This is used to fetch the list of rights categories to show in the modal that shows what a role can do.
 *
 * Only dispatched when the UserProfileRoleRightsListModal is opened
 */
export function* fetchRightCategories() {
  try {
    // TODO: Add appropriate return type to generator so yield doesn't return any
    // @ts-expect-error TS(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
    const rights = yield call([CoreRolesApi, CoreRolesApi.getAllRights]);

    yield put(Actions.fetchRightsSuccess(rights));
  } catch (error) {
    console.error('Error fetching rights; ', error);
    yield put(Actions.fetchRightsFailure(error));
  }
}

export const rolesSagas = [
  takeEvery(Actions.FETCH_ROLE, fetchRole),
  takeEvery(Actions.FETCH_RIGHTS, fetchRightCategories)
];

export default function* rootSaga() {
  yield all([...teamSagas, ...appTokenSagas, ...rolesSagas]);
}
